import { Elm } from "./Main.elm"


// HELPERS
JSON.tryParse = function (value) {
    try {
        return JSON.parse(value);
    } catch (error) {
        console.log(`JSON.tryParse error: ${error}`);
        return null;
    }
};


function storageAvailable(type) {
    let storage;
    try {
        storage = window[type];
        const x = "__storage_test__";
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return (
            e instanceof DOMException &&
            e.name === "QuotaExceededError" &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage &&
            storage.length !== 0
        );
    }
};
// END HELPERS

const APP_NAME = "PLANSJEQUIZ_1";
const SETTINGS_HINTTIME = `${APP_NAME}.userSettings.hintTime`;

var app = Elm.Main.init(
    {
        node: document.getElementById("root")
        , flags: null
    }
);

// INTEROP

function getQuizUri(id) {
    return "/json/" + id + ".json";
};

app.ports.fetchQuiz.subscribe(function (id) {
    console.log("fetchQuiz: " + getQuizUri(id));
    fetch(getQuizUri(id))
        .then(response => {
            if (!response.ok) {
                console.log(response);
                throw new Error('Network response was not ok');
            }
            return response.json(); // Parse the JSON from the response
        })
        .then(data => {
            console.log(data); // Handle the JSON data here
            app.ports.receiveQuiz.send(data);
        })
        .catch(error => {
            console.error('Error fetching JSON:', error);
            app.ports.quizNotFound.send("Quiz not found");
        });

});

app.ports.updateMetaDescription.subscribe(function (description) {
    const tag = document.querySelector('meta[name="description"]');
    if (tag) {
        tag.setAttribute("content", description);
    } else {
        const newMetaTag = document.createElement("meta");
        newMetaTag.setAttribute("name", "description");
        newMetaTag.setAttribute("content", description);
        document.head.appendChild(newMetaTag);
    }
});

/*app.ports.persistSettings.subscribe(function (settings) {
    if (!storageAvailable("localStorage"))
        return;

    localStorage.setItem(SETTINGS_HINTTIME, JSON.stringify(settings.hintTime ?? null));
}); */


