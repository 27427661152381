port module Main exposing (..)

import Browser exposing (UrlRequest)
import Browser.Dom as Dom
import Browser.Events as Events
import Browser.Navigation as Nav
import FrontPage
import Helpers.Geometry as Geometry
import Helpers.Keyboard as Keyboard
import Helpers.String as String
import Helpers.Unicode as Unicode
import Html as H exposing (Html)
import Html.Attributes as HA
import Icon
import Json.Decode as D
import Page exposing (Page)
import Page.Quiz
import Page.Quiz.CountriesOfTheWorld
import Page.Quiz.UsStates
import Quiz
import Random
import Random.List
import Route exposing (Route)
import Task
import Url exposing (Url)
import Url.Parser as Url


type alias Model =
    { appStatus : AppStatus
    , url : Url
    , navKey : Nav.Key
    , page : Page
    }


type AppStatus
    = LoadingApp
    | RunningApp { viewport : Geometry.Dimensions }


init : Url -> Nav.Key -> ( Model, Cmd Msg )
init url navKey =
    let
        ( page, cmds ) =
            case Route.parse url of
                Nothing ->
                    ( Page.NotFound
                    , []
                    )

                Just route ->
                    -- ((Route.Quiz quizId) as route) ->
                    case route of
                        Route.FrontPage ->
                            ( Page.Loading route
                            , [ Nav.pushUrl navKey (Url.toString url)
                              , fetchQuiz "land-i-verden"
                              ]
                            )

                        Route.Quiz quizId ->
                            ( Page.Loading route
                            , [ Nav.pushUrl navKey (Url.toString url)
                              , fetchQuiz quizId
                              ]
                            )
    in
    ( { appStatus = LoadingApp
      , url = url
      , navKey = navKey
      , page = page
      }
    , Cmd.batch <|
        (Dom.getViewport
            |> Task.map (\{ viewport } -> Geometry.dimensions (floor viewport.width) (floor viewport.height))
            |> Task.perform GotViewport
        )
            :: cmds
    )


type Msg
    = NoOp
    | QuizUpdated Page.Quiz.Model
    | QuizCountriesOfTheWorldUpdated Page.Quiz.CountriesOfTheWorld.Command Page.Quiz.CountriesOfTheWorld.Model
    | QuizUsStatesUpdated Page.Quiz.UsStates.Command Page.Quiz.UsStates.Model
    | QuizUsStatesItemsShuffled (List Quiz.QuizItemDynamic -> ( Page.Quiz.UsStates.Model, Page.Quiz.UsStates.Command )) (List Quiz.QuizItemDynamic)
    | OnKeyDown Keyboard.Key
    | Tick Float
    | UrlRequested UrlRequest
    | OnUrlChange Url
    | GotViewport Geometry.Dimensions
    | QuizReceived D.Value
    | QuizNotFound String


update : Msg -> Model -> ( Model, Cmd Msg )
update msg ({ page } as model) =
    case msg of
        NoOp ->
            ( model, Cmd.none )

        QuizUpdated quizpage ->
            ( { model
                | page = Page.QuizPage quizpage
              }
            , Cmd.none
            )

        QuizCountriesOfTheWorldUpdated quizcommand quizpage ->
            ( { model
                | page = Page.QuizCountriesOfTheWorld quizpage
              }
            , case quizcommand of
                Page.Quiz.CountriesOfTheWorld.NoCommand ->
                    Cmd.none

                Page.Quiz.CountriesOfTheWorld.FocusNode node ->
                    Task.attempt (\_ -> NoOp) (Dom.focus node)
            )

        QuizUsStatesUpdated quizcommand quizpage ->
            ( { model
                | page = Page.QuizUsStates quizpage
              }
            , case quizcommand of
                Page.Quiz.UsStates.NoCommand ->
                    Cmd.none

                Page.Quiz.UsStates.FocusNode node ->
                    Cmd.none

                Page.Quiz.UsStates.ShuffleItems callback items ->
                    Random.generate (QuizUsStatesItemsShuffled callback) (Random.List.shuffle items)
            )

        QuizUsStatesItemsShuffled callback items ->
            let
                ( quizpage, quizcommand ) =
                    callback items
            in
            ( { model
                | page = Page.QuizUsStates quizpage
              }
            , case quizcommand of
                Page.Quiz.UsStates.FocusNode node ->
                    Task.attempt (\_ -> NoOp) (Dom.focus node)

                _ ->
                    Cmd.none
            )

        OnKeyDown key ->
            case page of
                Page.QuizCountriesOfTheWorld quizpage ->
                    ( { model | page = Page.QuizCountriesOfTheWorld <| Page.Quiz.CountriesOfTheWorld.onKeydown key quizpage }
                    , Task.attempt (\_ -> NoOp) (Dom.focus "guess-input")
                    )

                _ ->
                    ( model, Cmd.none )

        Tick ms ->
            case page of
                Page.FrontPage frontpage ->
                    ( { model | page = Page.FrontPage { frontpage | tick = 1 + frontpage.tick } }, Cmd.none )

                Page.QuizPage quizpage ->
                    ( { model | page = Page.QuizPage (Page.Quiz.tick ms quizpage) }, Cmd.none )

                Page.QuizCountriesOfTheWorld quizpage ->
                    ( { model | page = Page.QuizCountriesOfTheWorld (Page.Quiz.CountriesOfTheWorld.tick ms quizpage) }, Cmd.none )

                Page.QuizUsStates quizpage ->
                    ( { model | page = Page.QuizUsStates (Page.Quiz.UsStates.tick ms quizpage) }
                    , Cmd.none
                    )

                _ ->
                    ( model, Cmd.none )

        UrlRequested urlRequest ->
            case urlRequest of
                Browser.Internal url ->
                    case Route.parse url of
                        Nothing ->
                            ( model
                            , Nav.pushUrl model.navKey (Url.toString url)
                            )

                        Just route ->
                            -- ((Route.Quiz quizId) as route) ->
                            case route of
                                Route.FrontPage ->
                                    ( model
                                    , Nav.pushUrl model.navKey (Url.toString url)
                                    )

                                Route.Quiz quizId ->
                                    ( model
                                    , Nav.pushUrl model.navKey (Url.toString url)
                                    )

                Browser.External _ ->
                    ( model, Cmd.none )

        OnUrlChange url ->
            case Route.parse url of
                Nothing ->
                    ( { model | page = Page.NotFound }
                    , Cmd.none
                    )

                Just route ->
                    -- ((Route.Quiz quizId) as route) ->
                    case route of
                        Route.FrontPage ->
                            ( { model
                                | page = Page.Loading route
                              }
                            , Cmd.batch
                                [ fetchQuiz "land-i-verden"
                                , updateMetaDescription "Quizleis har spill som vil quizze deg innen bl a geografi. Klarer du fylle verdenskartet, eller navngi delstatene i USA?"
                                ]
                            )

                        Route.Quiz quizId ->
                            ( { model
                                | page = Page.Loading route
                              }
                            , Cmd.batch
                                [ fetchQuiz quizId
                                , updateMetaDescription <|
                                    case quizId of
                                        "land-i-verden" ->
                                            "En morsom og litt annerledes geografi quiz. Består av alle verdens land — eller begrens området til et kontinent (Europa, Afrika, Asia etc). Fyll kartet med land!"

                                        "stater-i-usa" ->
                                            "Kan du navnet på delstater i USA? En quiz der du finner ut hvilke stater i USA du klarer plassere, og som vil hjelpe deg videre ved å gi hint på de du stusser litt på."

                                        _ ->
                                            "Quizleis har spill som vil quizze deg innen bl a geografi. Klarer du fylle verdenskartet, eller navngi delstatene i USA?"
                                ]
                            )

        GotViewport vp ->
            ( { model | appStatus = RunningApp { viewport = vp } }, Cmd.none )

        QuizReceived json ->
            let
                decodeResult =
                    D.decodeValue (D.field "data" Quiz.decoder) json
            in
            case decodeResult of
                Result.Err error ->
                    case page of
                        Page.Loading _ ->
                            ( { model | page = Page.NotFound }, Cmd.none )

                        _ ->
                            ( model, Cmd.none )

                Ok quiz ->
                    case quiz.display of
                        Quiz.StaticImage staticquiz ->
                            ( { model | page = Page.QuizPage (Page.Quiz.init 5000 staticquiz) }, Cmd.none )

                        Quiz.DynamicImageCountry contryquiz ->
                            case page of
                                Page.Loading route ->
                                    case route of
                                        Route.FrontPage ->
                                            ( { model | page = Page.FrontPage (FrontPage.init contryquiz) }
                                            , Cmd.none
                                            )

                                        _ ->
                                            ( { model | page = Page.QuizCountriesOfTheWorld (Page.Quiz.CountriesOfTheWorld.init contryquiz) }
                                            , Cmd.none
                                            )

                                _ ->
                                    ( model
                                    , Cmd.none
                                    )

                        Quiz.DynamicImage dynamicquiz ->
                            ( { model | page = Page.QuizUsStates (Page.Quiz.UsStates.init dynamicquiz) }, Cmd.none )

        QuizNotFound _ ->
            case page of
                Page.Loading _ ->
                    ( { model | page = Page.NotFound }, Cmd.none )

                _ ->
                    ( model, Cmd.none )


view : Model -> Html Msg
view { appStatus, page } =
    case appStatus of
        LoadingApp ->
            H.text "Loading App"

        RunningApp { viewport } ->
            case page of
                Page.FrontPage frontpage ->
                    viewFrontpage viewport frontpage

                subPage ->
                    Page.view
                        viewport
                        (if viewport.w < 800 then
                            viewOverlay SmallViewOverlay

                         else
                            viewOverlay ViewOverlay
                        )
                        QuizUpdated
                        QuizCountriesOfTheWorldUpdated
                        QuizUsStatesUpdated
                        subPage


fontSize =
    20


viewFrontpage : Geometry.Dimensions -> FrontPage.Model -> Html Msg
viewFrontpage viewport { featuredQuiz, tick } =
    let
        containerWidth =
            min 800 viewport.w

        isSmallScreen =
            viewport.w < 800

        splitItemWidth =
            if isSmallScreen then
                containerWidth

            else
                containerWidth // 2

        styleBorderTop =
            if isSmallScreen then
                HA.style "border-radius" "0 0 0 0"

            else
                HA.style "border-radius" "10px 10px 0 0"

        styleBorderBottom =
            if isSmallScreen then
                HA.style "border-radius" "0 0 0 0"

            else
                HA.style "border-radius" "0 0 10px 10px"
    in
    H.div
        [ HA.style "display" "flex"
        , HA.style "flex-direction" "column"
        , HA.style "min-height" "100%"
        , HA.style "align-items" "center"
        , HA.style "width" "100%"
        , HA.style "justify-content" "center"
        , HA.style "background-color" (FrontPage.backgroundColor 1)
        ]
        [ H.div
            [ HA.style "display" "flex"
            , HA.style "flex-direction" "column"
            , HA.style "width" (containerWidth |> String.px)
            , HA.style "padding-left" "20px"
            , HA.style "padding-right" "20px"
            , HA.style "color" "#333333"
            , HA.style "background-color" "rgba(255,255,255,0.6)"
            , styleBorderTop
            ]
            [ H.h1
                [ HA.style "font-family" "chewy;serif"
                , HA.style "font-size" "60px"
                , HA.style "display" "flex"
                ]
                [ Icon.viewText (min (containerWidth // 10) 60) "Velkommen til Quizleis!"
                ]
            , H.div
                [ HA.style "font-size" (String.px fontSize)
                ]
                [ H.p
                    []
                    [ Unicode.toFixedWidth 2 Unicode.ThumbsUp, H.text " Quizleis er en splitter ny nettside. Moro at du tok turen så tidlig!" ]
                , H.p
                    []
                    [ Unicode.toFixedWidth 2 Unicode.ThinkingFace, H.text " Her vil det jevnt og trutt komme små spill som quizzer deg innen et bredt utvalg emner." ]
                , H.p
                    []
                    [ Unicode.toFixedWidth 2 Unicode.DownPointingFinger, H.text " Inntil videre — hvorfor ikke bryne geografi-kunnskapene dine?" ]
                ]
            ]
        , H.a
            [ HA.style "display" "flex"
            , if isSmallScreen then
                HA.style "flex-direction" "column"

              else
                HA.style "flex-direction" "row"

            -- , HA.style "justify-content" "space-between"
            , HA.style "width" (containerWidth |> String.px)

            -- , HA.style "background-color" "rgba(255,255,255,0.6)"
            , HA.style "text-decoration" "none"
            , HA.href "/quiz/land-i-verden"
            ]
            [ H.div
                [ HA.style "order"
                    (if isSmallScreen then
                        "2"

                     else
                        "1"
                    )
                , HA.style "background-color" (Page.Quiz.CountriesOfTheWorld.backgroundColor 1)
                , HA.style "width" (splitItemWidth |> String.px)

                -- , HA.style "border-radius" "20px"
                ]
                [ Page.Quiz.CountriesOfTheWorld.viewImage (Geometry.dimensions splitItemWidth 250) (Geometry.rectangle Geometry.origin featuredQuiz.dimensions) (List.take (tick // FrontPage.ticksPerReveal) featuredQuiz.items) ]
            , H.div
                [ HA.style "order"
                    (if isSmallScreen then
                        "1"

                     else
                        "2"
                    )
                , HA.style "background-color" (Page.Quiz.CountriesOfTheWorld.buttonColor 1)
                , HA.style "padding" "16px"
                , HA.style "color" "#444"
                , HA.style "width" (splitItemWidth |> String.fromInt)
                ]
                [ H.h2 [] [ Icon.viewText 26 "Fyll ut kartet" ]
                , H.p
                    [ HA.style "font-size" (String.px fontSize) ]
                    [ H.text "En geografi-quiz der kartet fylles ut etter hvert land du skriver inn. Quiz med land fra hele verden, eller velg en verdensdel." ]
                , H.p
                    [ HA.style "font-size" (String.px fontSize)
                    , HA.style "font-style" "italic"
                    ]
                    [ H.text "Klokken tikker! Hvor mye av kartet klarer du fylle?" ]
                ]
            ]
        , H.a
            [ HA.style "display" "flex"
            , if isSmallScreen then
                HA.style "flex-direction" "column"

              else
                HA.style "flex-direction" "row"

            -- , HA.style "justify-content" "space-between"
            -- , HA.style "background-color" "rgba(255,255,255,0.6)"
            , HA.style "text-decoration" "none"
            , HA.href "/quiz/stater-i-usa"
            , HA.style "width" (containerWidth |> String.fromInt)
            ]
            [ H.div
                [ HA.style "order" "1"
                , HA.style "background-color" (Page.Quiz.UsStates.buttonColor 0.1)
                , HA.style "color" "white"
                , HA.style "padding" "16px"
                , HA.style "color" "#444"
                , HA.style "width" (containerWidth |> String.px)
                ]
                [ H.h2 [] [ Icon.viewText 26 "Stater i USA" ]
                , H.p
                    [ HA.style "font-size" (String.px fontSize) ]
                    [ H.text "Kan du navnet på alle statene i USA? Hvis ikke vil du bli hjulpet gjennom med de du ikke kan." ]
                , H.p
                    [ HA.style "font-size" (String.px fontSize)
                    , HA.style "font-style" "italic"
                    ]
                    [ H.text "Hvor få hint trenger du?" ]
                ]
            ]
        , H.div
            [ HA.style "display" "flex"
            , HA.style "justify-content" "center"
            , HA.style "background-color" "rgba(255,255,255,0.6)"
            , HA.style "width" (containerWidth |> String.px)
            , HA.style "padding-top" "5px"
            , styleBorderBottom
            ]
            [ Icon.viewIcon ]
        ]


type ViewOverlay
    = ViewOverlay
    | SmallViewOverlay


viewOverlay : ViewOverlay -> Html Msg
viewOverlay type_ =
    case type_ of
        ViewOverlay ->
            H.div
                [ HA.style "position" "absolute"
                , HA.style "left" (String.px 20)
                , HA.style "top" (String.px 20)
                , HA.style "padding" (String.px 20)
                , HA.style "border-radius" (String.px 10)
                , HA.style "background" "rgb(255,255,255,0.6)"
                ]
                [ H.a
                    [ HA.href "/"
                    , HA.class "font-mono"
                    , HA.style "color" "black"
                    , HA.style "font-size" "16px"
                    ]
                    [ H.text "Hjem" ]
                ]

        SmallViewOverlay ->
            H.div
                [ HA.style "position" "absolute"
                , HA.style "left" (String.px 5)
                , HA.style "top" (String.px 5)
                , HA.style "padding" (String.px 2)
                , HA.style "border-radius" (String.px 0)
                ]
                [ H.a
                    [ HA.href "/"
                    , HA.class "font-mono"
                    , HA.style "color" "black"
                    , HA.style "font-size" "18px"
                    ]
                    [ H.text "Hjem" ]
                ]


main : Program () Model Msg
main =
    Browser.application
        { init = \_ url navKey -> init url navKey
        , update = update
        , view = \model -> { title = "Quizleis", body = [ view model ] }
        , subscriptions = subscriptions
        , onUrlRequest = UrlRequested
        , onUrlChange = OnUrlChange
        }



-- interop


port fetchQuiz : String -> Cmd msg


port updateMetaDescription : String -> Cmd msg


port receiveQuiz : (D.Value -> msg) -> Sub msg


port quizNotFound : (String -> msg) -> Sub msg


subscriptions : Model -> Sub Msg
subscriptions { page } =
    let
        conditional =
            case page of
                Page.FrontPage frontpage ->
                    if not <| FrontPage.isFinished frontpage then
                        [ Events.onAnimationFrameDelta Tick ]

                    else
                        []

                Page.QuizPage _ ->
                    [ Events.onAnimationFrameDelta Tick ]

                Page.QuizCountriesOfTheWorld { status } ->
                    case status of
                        Page.Quiz.CountriesOfTheWorld.Quizzing _ ->
                            [ Events.onAnimationFrameDelta Tick
                            , Events.onKeyDown (D.map OnKeyDown Keyboard.keyDecoder)
                            ]

                        _ ->
                            []

                Page.QuizUsStates { status } ->
                    case status of
                        Page.Quiz.UsStates.Quizzing _ ->
                            [ Events.onAnimationFrameDelta Tick ]

                        _ ->
                            []

                _ ->
                    [ receiveQuiz QuizReceived
                    , quizNotFound QuizNotFound
                    ]
    in
    Sub.batch <|
        Events.onResize (\w h -> Geometry.dimensions w h |> GotViewport)
            :: conditional
